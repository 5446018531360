import { SchemeType } from './scheme-types.model';

export interface SchemeTypesState {
  schemeTypes: SchemeType[];
  schemeType: SchemeType;
  loadingSchemeTypes: boolean;
  loadingSchemeType: boolean;
  schemeTypeUpdated: boolean;
  schemeTypeDeleted: boolean;
}

export const emptySchemeType: SchemeType = {
  id: null,
  name: '',
  order: 0,
};

export const initialSchemeTypesState: SchemeTypesState = {
  schemeTypes: [],
  schemeType: emptySchemeType,
  loadingSchemeTypes: false,
  loadingSchemeType: false,
  schemeTypeUpdated: false,
  schemeTypeDeleted: false,
};
